<template>
  <base-dialog v-model="dialog" title="Termos do conta" :max-width="1500">
    <v-row>
      <v-col class="mx-12">
        <v-card>
          <v-card-text>
            {{ termContent }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'
export default {
  components: {
    BaseDialog
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Modal title
     */
    termContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      /**
       * Update the model
       * @event input
       */
      this.$emit('input', this.dialog)
    }
  }
}
</script>
